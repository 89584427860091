import './src/lottie_animations'
import {VideoResponser} from "./src/video";
import {countUpAnimation} from "./src/count_up";
import {supportOutsideCarouselIndicators} from "./src/carousel_outside_indicators";

function registerCountUpAnimation() {
    const element = document.querySelector('.home-bubbles-container');
    if (element) {
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    countUpAnimation('.countUp');
                    observer.unobserve(element);
                }
            });
        }, {threshold: 0.1});
        observer.observe(element);
    }
}

document.addEventListener('DOMContentLoaded', function () {
    new VideoResponser('#home-video');
    supportOutsideCarouselIndicators('home-testimonial-carousel', ['home-testimonial-indicators']);
    supportOutsideCarouselIndicators('home-security-carousel', ['text-carousel-outside-indicators', 'text-carousel-below-indicators']);
    registerCountUpAnimation();
});
